/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Gets MOUserVerificationBoolean from api:
 * /v1/ua/mo_user_validated
 *
 * @param {string} username.
 * @param {any} callback function to call once this function has finished
 */
const fetchMoUserValidatedUtil = (username, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mo_user_validated?username=${username}`,
    models.MOUserVerificationBoolean
  );
};

/**
 * Post request to path:
 * /v1/ua/mo_verify_user
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMoVerifyUserUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mo_verify_user`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewMORIDPPrimaryUserResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/mo_secondary_verify_user
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMoSecondaryVerifyUserUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mo_secondary_verify_user`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewMORIDPPrimaryUserResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

export { fetchMoUserValidatedUtil, postMoVerifyUserUtil, postMoSecondaryVerifyUserUtil };
