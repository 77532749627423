/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for RIDPModule.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_MO_USER_VALIDATED = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `RIDPMODULE/FETCH_MO_USER_VALIDATED`
);

const POST_MO_VERIFY_USER = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `RIDPMODULE/POST_MO_VERIFY_USER`
);

const POST_MO_SECONDARY_VERIFY_USER = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `RIDPMODULE/POST_MO_SECONDARY_VERIFY_USER`
);

export { FETCH_MO_USER_VALIDATED, POST_MO_VERIFY_USER, POST_MO_SECONDARY_VERIFY_USER };
