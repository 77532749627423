import { defineMessages } from 'react-intl';

export default defineMessages({
  // BEGIN About the Agency column
  aboutTheAgencyHeader: {
    id: 'MOFooter_AboutTheAgency_Header',
    defaultMessage: 'About the Agency',
  },
  aboutDSS: {
    id: 'MOFooter_AboutDSS',
    defaultMessage: 'About DSS'
  },
  aboutFSD: {
    id: 'MOFooter_AboutFSD',
    defaultMessage: 'About Family Support Division'
  },
  ourDivisions: {
    id: 'MOFooter_OurDivision',
    defaultMessage: 'Our Divisions'
  },
  dssMOGov: {
    id: 'MOFooter_DSS_MO_Gov',
    defaultMessage: 'DSS.mo.gov'
  },
  statsReports: {
    id: 'MOFooter_StatsReports',
    defaultMessage: 'Statistics & Reports'
  },
  newsMedia: {
    id: 'MOFooter_NewsMedia',
    defaultMessage: 'News & Media'
  },
  careers: {
    id: 'MOFooter_Careers',
    defaultMessage: 'Career Opportunities'
  },
  strategic: {
    id: 'MOFooter_Strategic',
    defaultMessage: 'Strategic Management Priorities'
  },
  phoneNumbers: {
    id: 'MOFooter_PhoneNumbers',
    defaultMessage: 'Toll-Free Numbers'
  },
  nondiscrimination: {
    id: 'MOFooter_Nondiscrimination',
    defaultMessage: 'DSS Nondiscrimination Policy'
  },
  nondiscriminationSpanish: {
    id: 'MOFooter_NondiscriminationSpanish',
    defaultMessage: 'DSS Nondiscrimination Policy - Spanish'
  },
  // END About the Agency column
  // BEGIN Popular Links column
  popularLinksHeader: {
    id: 'MOFooter_PopularLinks_Header',
    defaultMessage: 'Popular Links',
  },
  fraud: {
    id: 'MOFooter_ReportFraud',
    defaultMessage: 'Report Fraud'
  },
  energy: {
    id: 'MOFooter_EndergyAssistance',
    defaultMessage: 'Energy Assistance'
  },
  childCare: {
    id: 'MOFooter_ChildCare',
    defaultMessage: 'Child Care'
  },
  meetings: {
    id: 'MOFooter_Meetings',
    defaultMessage: 'Open Meeting Notices'
  },
  privacyForms: {
    id: 'MOFooter_PrivacyForms',
    defaultMessage: 'Privacy Forms'
  },
  // END Popular Links column
  // BEGIN Resources column
  resourcesHeader: {
    id: 'MOFooter_resourcesHeader',
    defaultMessage: 'Resources'
  },
  opioids: {
    id: 'MOFooter_opioids',
    defaultMessage: 'Help with Opioid Misuse'
  },
  covid: {
    id: 'MOFooter_covid',
    defaultMessage: 'COVID-19 Information'
  },
  unitedWay: {
    id: 'MOFooter_unitedWay',
    defaultMessage: '2-1-1 Missouri, United Way'
  },
  registerToVote: {
    id: 'MOFooter_registerToVote',
    defaultMessage: 'Register to Vote'
  },
  influenza: {
    id: 'MOFooter_influenza',
    defaultMessage: 'Influenza Information'
  },
  healthBenefitAccount: {
    id: 'MOFooter_healthBenefitAccount',
    defaultMessage: 'Health Benefit Account'
  },
  preumptiveEligibility: {
    id: 'MOFooter_preumptiveEligibility',
    defaultMessage: 'Presumptive Eligibility Resources'
  },
  getViewers: {
    id: 'MOFooter_getViewers',
    defaultMessage: 'Get viewers'
  },
  // END Resources column
  // BEGIN Agency Details
  governorTitle: {
    id: 'MOFooter_governorTitle',
    defaultMessage: 'Governor'
  },
  governorName: {
    id: 'MOFooter_governorName',
    defaultMessage: 'Mike Kehoe'
  },
  moGovTitleText: {
    id: 'MOFooter_moGovTitleText',
    defaultMessage: 'MO.gov | Official State of Missouri Website'
  },
  directoryTitle: {
    id: 'MOFooter_directoryTitle',
    defaultMessage: 'Acting Director'
  },
  directoryName: {
    id: 'MOFooter_directoryName',
    defaultMessage: 'Jessica Bax'
  },
  // END Agency Details
  // BEGIN Footer Info
  privacyPolicy: {
    id: 'MOFooter_privacyPolicy',
    defaultMessage: 'Privacy Policy'
  },
  accessibility: {
    id: 'MOFooter_accessibility',
    defaultMessage: 'Accessibility'
  },
  contactUs: {
    id: 'MOFooter_contactUs',
    defaultMessage: 'Contact Us'
  },
  siteMap: {
    id: 'MOFooter_siteMap',
    defaultMessage: 'Site Map'
  },
  proposedRules: {
    id: 'MOFooter_proposedRules',
    defaultMessage: 'DSS Proposed Rules'
  },
  commentOnRules: {
    id: 'MOFooter_commentOnRules',
    defaultMessage: 'Comment on a DSS Rule'
  },
  // END Footer Info
  // BEGIN EOE and Auxiliary Aids
  equalOpportunity: {
    id: 'MOFooter_equalOpportunity',
    defaultMessage: 'Missouri Department of Social Services is an equal opportunity employer/program.'
  },
  auxiliaryAids: {
    id: 'MOFooter_auxiliaryAids',
    defaultMessage: 'Auxiliary aids and services are available upon request to individuals with disabilities. TDD/TTY: 800-735-2966, Relay Missouri: 711'
  },
  // END EOE and Auxiliary Aids
});
