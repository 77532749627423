/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Post request to path:
 * /v1/ua/mo_secret_question_password_reset
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMoSecretQuestionPasswordResetUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mo_secret_question_password_reset`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

/**
 * Gets MOCheckUsernameBoolean from api:
 * /v1/ua/mo_username_exists
 *
 * @param {string} username.
 * @param {any} callback function to call once this function has finished
 */
const fetchMoUsernameExistsUtil = (username, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mo_username_exists?username=${username}`,
    models.MOCheckUsernameBoolean
  );
};

export { postMoSecretQuestionPasswordResetUtil, fetchMoUsernameExistsUtil };
