/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  POST_MO_EDIT_EMAIL_ADDRESS,
  POST_MO_CREATE_EMAIL_ADDRESS,
  POST_MO_CREATE_PHONE_NUMBER,
  POST_MO_EDIT_PHONE_NUMBER,
  FETCH_MO_LIST_CONTACT_INFORMATION,
} from './actionTypes';

import {
  postMoEditEmailAddressUtil,
  postMoCreateEmailAddressUtil,
  postMoCreatePhoneNumberUtil,
  postMoEditPhoneNumberUtil,
  fetchMoListContactInformationUtil,
} from './utils';

/**
 * Contains the action creator functions that update the ContactInformationModule slice of the store.
 *
 * @namespace ContactInformationModuleActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mo_edit_emailAddress
   *
   * @param {NewMOEmailAddress} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof ContactInformationModuleActions
   */
  static postMoEditEmailAddress = (dispatch, newMOEmailAddress, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMoEditEmailAddressUtil.bind(null, newMOEmailAddress),
      POST_MO_EDIT_EMAIL_ADDRESS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMoEditEmailAddress.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof ContactInformationModuleActions
   */
  static resetPostMoEditEmailAddress = (dispatch, data) => {
    const json = {
      type: POST_MO_EDIT_EMAIL_ADDRESS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMoEditEmailAddress.
   *
   * @param {function} dispatch
   * @function
   * @memberof ContactInformationModuleActions
   */
  static resetPostMoEditEmailAddressError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MO_EDIT_EMAIL_ADDRESS.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mo_create_emailAddress
   *
   * @param {NewMOEmailAddress} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof ContactInformationModuleActions
   */
  static postMoCreateEmailAddress = (dispatch, newMOEmailAddress, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMoCreateEmailAddressUtil.bind(null, newMOEmailAddress),
      POST_MO_CREATE_EMAIL_ADDRESS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMoCreateEmailAddress.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof ContactInformationModuleActions
   */
  static resetPostMoCreateEmailAddress = (dispatch, data) => {
    const json = {
      type: POST_MO_CREATE_EMAIL_ADDRESS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMoCreateEmailAddress.
   *
   * @param {function} dispatch
   * @function
   * @memberof ContactInformationModuleActions
   */
  static resetPostMoCreateEmailAddressError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MO_CREATE_EMAIL_ADDRESS.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mo_create_phoneNumber
   *
   * @param {NewMOPhoneNumber} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof ContactInformationModuleActions
   */
  static postMoCreatePhoneNumber = (dispatch, newMOPhoneNumber, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMoCreatePhoneNumberUtil.bind(null, newMOPhoneNumber),
      POST_MO_CREATE_PHONE_NUMBER,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMoCreatePhoneNumber.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof ContactInformationModuleActions
   */
  static resetPostMoCreatePhoneNumber = (dispatch, data) => {
    const json = {
      type: POST_MO_CREATE_PHONE_NUMBER.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMoCreatePhoneNumber.
   *
   * @param {function} dispatch
   * @function
   * @memberof ContactInformationModuleActions
   */
  static resetPostMoCreatePhoneNumberError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MO_CREATE_PHONE_NUMBER.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mo_edit_phoneNumber
   *
   * @param {NewMOPhoneNumber} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof ContactInformationModuleActions
   */
  static postMoEditPhoneNumber = (dispatch, newMOPhoneNumber, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMoEditPhoneNumberUtil.bind(null, newMOPhoneNumber),
      POST_MO_EDIT_PHONE_NUMBER,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMoEditPhoneNumber.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof ContactInformationModuleActions
   */
  static resetPostMoEditPhoneNumber = (dispatch, data) => {
    const json = {
      type: POST_MO_EDIT_PHONE_NUMBER.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMoEditPhoneNumber.
   *
   * @param {function} dispatch
   * @function
   * @memberof ContactInformationModuleActions
   */
  static resetPostMoEditPhoneNumberError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MO_EDIT_PHONE_NUMBER.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mo_list_contactInformation
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof ContactInformationModuleActions
   */
  static fetchMoListContactInformation = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchMoListContactInformationUtil,
      FETCH_MO_LIST_CONTACT_INFORMATION,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMoListContactInformation.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof ContactInformationModuleActions
   */
  static resetFetchMoListContactInformation = (dispatch, data) => {
    const json = {
      type: FETCH_MO_LIST_CONTACT_INFORMATION.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMoListContactInformation.
   *
   * @param {function} dispatch
   * @function
   * @memberof ContactInformationModuleActions
   */
  static resetFetchMoListContactInformationError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MO_LIST_CONTACT_INFORMATION.failureAction);
  };
}
