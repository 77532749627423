/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the MOUserVerificationBoolean
 *
 * @class MOUserVerificationBoolean
 */
class MOUserVerificationBoolean {
  /**
   * Creates an instance of MOUserVerificationBoolean.
   * @param {*} json
   * @memberof MOUserVerificationBoolean
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.verifiedUser = json.verifiedUser;
    }
  }
}

/**
 * Models the NewMOUserDetails
 *
 * @class NewMOUserDetails
 */
class NewMOUserDetails {
  /**
   * Creates an instance of NewMOUserDetails.
   * @param {*} json
   * @memberof NewMOUserDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.firstName = json.firstName;

      // string
      this.lastName = json.lastName;

      // string
      this.middleName = json.middleName;

      // string
      this.address = json.address;

      // string
      this.socialSecurityNumber = json.socialSecurityNumber;

      // string
      this.dateOfBirth = json.dateOfBirth;

      // string
      this.city = json.city;

      // string
      this.state = json.state;

      // string
      this.zipCode = json.zipCode;

      // string
      this.preferredLanguage = json.preferredLanguage;

      // string
      this.suffix = json.suffix;

      // string
      this.phoneNumber = json.phoneNumber;

      // string
      this.username = json.username;
    }
  }
}

/**
 * Models the NewMORIDPVerificationAnswerResponse
 *
 * @class NewMORIDPVerificationAnswerResponse
 */
class NewMORIDPVerificationAnswerResponse {
  /**
   * Creates an instance of NewMORIDPVerificationAnswerResponse.
   * @param {*} json
   * @memberof NewMORIDPVerificationAnswerResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.answer = json.answer;
    }
  }
}

/**
 * Models the NewMORIDPVerificationQuestionResponse
 *
 * @class NewMORIDPVerificationQuestionResponse
 */
class NewMORIDPVerificationQuestionResponse {
  /**
   * Creates an instance of NewMORIDPVerificationQuestionResponse.
   * @param {*} json
   * @memberof NewMORIDPVerificationQuestionResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.question = json.question;

      // array - NewMORIDPVerificationAnswerResponse
      if (json.answers) {
        this.answers = json.answers.map(object => new NewMORIDPVerificationAnswerResponse(object));
      } else if (json.constructor === Array) {
        this.answers = json.map(object => new NewMORIDPVerificationAnswerResponse(object));
      }
    }
  }
}

/**
 * Models the NewMORIDPVerificationQuestionListResponse
 *
 * @class NewMORIDPVerificationQuestionListResponse
 */
class NewMORIDPVerificationQuestionListResponse {
  /**
   * Creates an instance of NewMORIDPVerificationQuestionListResponse.
   * @param {*} json
   * @memberof NewMORIDPVerificationQuestionListResponse
   */
  constructor(json = null) {
    if (json) {
      // array - NewMORIDPVerificationQuestionResponse
      if (json.questionList) {
        this.questionList = json.questionList.map(
          object => new NewMORIDPVerificationQuestionResponse(object)
        );
      } else if (json.constructor === Array) {
        this.questionList = json.map(object => new NewMORIDPVerificationQuestionResponse(object));
      }
    }
  }
}

/**
 * Models the NewMORIDPPrimaryUserResponseResponse
 *
 * @class NewMORIDPPrimaryUserResponseResponse
 */
class NewMORIDPPrimaryUserResponseResponse {
  /**
   * Creates an instance of NewMORIDPPrimaryUserResponseResponse.
   * @param {*} json
   * @memberof NewMORIDPPrimaryUserResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.sessionID = json.sessionID;

      // string
      this.responseCode = json.responseCode;

      // string
      this.responseCodeDescription = json.responseCodeDescription;

      // string
      this.dshReferenceNumber = json.dshReferenceNumber;

      // string
      this.finalDecisionCode = json.finalDecisionCode;

      // object - NewMORIDPVerificationQuestionListResponse
      if (json.questionList) {
        this.questionList = new NewMORIDPVerificationQuestionListResponse(json.questionList);
      }
    }
  }
}

/**
 * Models the NewMOSecondaryUserDetails
 *
 * @class NewMOSecondaryUserDetails
 */
class NewMOSecondaryUserDetails {
  /**
   * Creates an instance of NewMOSecondaryUserDetails.
   * @param {*} json
   * @memberof NewMOSecondaryUserDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.sessionID = json.sessionID;

      // string
      this.questionAndAnswers = json.questionAndAnswers;

      // string
      this.dshReferenceNumber = json.dshReferenceNumber;
    }
  }
}

export default {
  MOUserVerificationBoolean,

  NewMOUserDetails,

  NewMORIDPVerificationAnswerResponse,

  NewMORIDPVerificationQuestionResponse,

  NewMORIDPVerificationQuestionListResponse,

  NewMORIDPPrimaryUserResponseResponse,

  NewMOSecondaryUserDetails,
};
