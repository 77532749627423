/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Post request to path:
 * /v1/ua/mo_edit_emailAddress
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMoEditEmailAddressUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mo_edit_emailAddress`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

/**
 * Post request to path:
 * /v1/ua/mo_create_emailAddress
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMoCreateEmailAddressUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mo_create_emailAddress`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

/**
 * Post request to path:
 * /v1/ua/mo_create_phoneNumber
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMoCreatePhoneNumberUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mo_create_phoneNumber`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

/**
 * Post request to path:
 * /v1/ua/mo_edit_phoneNumber
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMoEditPhoneNumberUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mo_edit_phoneNumber`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

/**
 * Gets MOContactInformation from api:
 * /v1/ua/mo_list_contactInformation
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchMoListContactInformationUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mo_list_contactInformation`,
    models.MOContactInformation
  );
};

export {
  postMoEditEmailAddressUtil,
  postMoCreateEmailAddressUtil,
  postMoCreatePhoneNumberUtil,
  postMoEditPhoneNumberUtil,
  fetchMoListContactInformationUtil,
};
