/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for ContactInformationModule.
 *
 */

import { ReduxUtils } from '@spm/core';

const POST_MO_EDIT_EMAIL_ADDRESS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `CONTACTINFORMATIONMODULE/POST_MO_EDIT_EMAIL_ADDRESS`
);

const POST_MO_CREATE_EMAIL_ADDRESS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `CONTACTINFORMATIONMODULE/POST_MO_CREATE_EMAIL_ADDRESS`
);

const POST_MO_CREATE_PHONE_NUMBER = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `CONTACTINFORMATIONMODULE/POST_MO_CREATE_PHONE_NUMBER`
);

const POST_MO_EDIT_PHONE_NUMBER = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `CONTACTINFORMATIONMODULE/POST_MO_EDIT_PHONE_NUMBER`
);

const FETCH_MO_LIST_CONTACT_INFORMATION = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `CONTACTINFORMATIONMODULE/FETCH_MO_LIST_CONTACT_INFORMATION`
);

export {
  POST_MO_EDIT_EMAIL_ADDRESS,
  POST_MO_CREATE_EMAIL_ADDRESS,
  POST_MO_CREATE_PHONE_NUMBER,
  POST_MO_EDIT_PHONE_NUMBER,
  FETCH_MO_LIST_CONTACT_INFORMATION,
};
