/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import { POST_MO_SECRET_QUESTION_PASSWORD_RESET, FETCH_MO_USERNAME_EXISTS } from './actionTypes';

import { postMoSecretQuestionPasswordResetUtil, fetchMoUsernameExistsUtil } from './utils';

/**
 * Contains the action creator functions that update the ForgotPasswordModule slice of the store.
 *
 * @namespace ForgotPasswordModuleActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mo_secret_question_password_reset
   *
   * @param {NewMOUASecretQuestionPasswordReset} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof ForgotPasswordModuleActions
   */
  static postMoSecretQuestionPasswordReset = (
    dispatch,
    newMOUASecretQuestionPasswordReset,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postMoSecretQuestionPasswordResetUtil.bind(null, newMOUASecretQuestionPasswordReset),
      POST_MO_SECRET_QUESTION_PASSWORD_RESET,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMoSecretQuestionPasswordReset.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof ForgotPasswordModuleActions
   */
  static resetPostMoSecretQuestionPasswordReset = (dispatch, data) => {
    const json = {
      type: POST_MO_SECRET_QUESTION_PASSWORD_RESET.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMoSecretQuestionPasswordReset.
   *
   * @param {function} dispatch
   * @function
   * @memberof ForgotPasswordModuleActions
   */
  static resetPostMoSecretQuestionPasswordResetError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MO_SECRET_QUESTION_PASSWORD_RESET.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mo_username_exists
   *
   * @param {string} username.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof ForgotPasswordModuleActions
   */
  static fetchMoUsernameExists = (dispatch, username, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchMoUsernameExistsUtil.bind(null, username),

      FETCH_MO_USERNAME_EXISTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMoUsernameExists.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof ForgotPasswordModuleActions
   */
  static resetFetchMoUsernameExists = (dispatch, data) => {
    const json = {
      type: FETCH_MO_USERNAME_EXISTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMoUsernameExists.
   *
   * @param {function} dispatch
   * @function
   * @memberof ForgotPasswordModuleActions
   */
  static resetFetchMoUsernameExistsError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MO_USERNAME_EXISTS.failureAction);
  };
}
