/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for ForgotPasswordModule.
 *
 */

import { ReduxUtils } from '@spm/core';

const POST_MO_SECRET_QUESTION_PASSWORD_RESET = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `FORGOTPASSWORDMODULE/POST_MO_SECRET_QUESTION_PASSWORD_RESET`
);

const FETCH_MO_USERNAME_EXISTS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `FORGOTPASSWORDMODULE/FETCH_MO_USERNAME_EXISTS`
);

export { POST_MO_SECRET_QUESTION_PASSWORD_RESET, FETCH_MO_USERNAME_EXISTS };
